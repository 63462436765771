import React, { useState, useEffect } from 'react';
import { Routes, Route, Link} from 'react-router-dom';
import VideoModal from './components/VideoModal';
import Akshaya from './components/akshya';
import Udupi from  './Components1/udupi'
import SIJE from './SIJE/sije'
import Mysuru from './Mysuru/Mysuru'
import Madurai from './Madurai/Madurai'
import Hyderabad from './Hyderabad/Hyderabad'
import ThankYouPage from'./SIJE/ThankYouPage'
import Home from './Home/home';

function App() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  useEffect(() => {
    setVideoModalOpen(false);
  }, []);

  const handleClose = () => {
    setVideoModalOpen(false);
  };

  return (
    <div className="app">
        <Link to="/akshayatritiya"></Link>
        {/* <InputBox />
        <VideoModal isOpen={videoModalOpen} onClose={handleClose} /> */}
      <Link to="/Udupi"></Link>
      <Link to="/SIJE"></Link>
      <Link to="/Mysuru"></Link>
      <Link to="/Madurai"></Link>
      <Link to="/Hyderabad"></Link>
      <Routes>
    
        <Route exact path="/akshayatritiya" element={<Akshaya />} />
        <Route exact path="/Udupi" element={<Udupi />} />
        <Route exact path="/SIJE" element={<SIJE />} />
        <Route exact path="/Mysuru" element={<Mysuru />} />
        <Route exact path="/Madurai" element={<Madurai />} />
        <Route exact path="/Hyderabad" element={<Hyderabad />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route
        exact  path="/"
          element={
            <div className="content">
              <Home />
              {/* <Mob/> */}
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
